/* eslint-disable camelcase */

import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { gaTrackingId } from '@jxl/environment';
import { setTag as setSentryTag, setUser as setSentryUser } from '@sentry/node';

import { AnalyticsConfigContainer } from '../../controllers/analytics-config';

const sha256 = async (input: string) => {
    const inputBuffer = new TextEncoder().encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', inputBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => `00${b.toString(16)}`.slice(-2)).join('');
    return hashHex;
};

interface InitArgs {
    baseConfig: Record<string, unknown>;
    userId: string;
    tenantUrl: string | undefined;
}

const toTenantHash = async (tenantUrl: string | undefined) => {
    try {
        return tenantUrl ? await sha256(tenantUrl) : 'n-a';
    } catch (e) {
        return 'n-a.encoding-failed';
    }
};

const init = async ({ baseConfig, userId, tenantUrl }: InitArgs) => {
    const tenantHash = await toTenantHash(tenantUrl);

    // xeslint-disable-next-line no-console
    // console.debug(`Setting tenant_hash to ${tenantHash}...`);

    gtag('set', 'user_properties', { tenant_hash: tenantHash });
    setSentryTag('tenant.hash', tenantHash);

    setSentryUser({ id: userId });

    // @ts-ignore
    delete window[`ga-disable-${gaTrackingId}`];

    window.gtag('config', gaTrackingId, {
        ...baseConfig,
        page_path: '/n-a',
        page_title: 'n-a',
        send_page_view: false,
    });
};

interface Props {
    userId: string;
    tenantUrl?: string;
    children?: ReactNode;
}

export const AnalyticsProvider = ({ userId, tenantUrl, children }: Props) => {
    const [initialized, setInitialized] = useState<boolean>(false);

    const internalTraffic =
        tenantUrl === 'daniel.atlassian.net' || tenantUrl === 'itsfine.atlassian.net';

    const baseConfig = useMemo(
        () => ({
            cookie_prefix: 'jxl',
            user_id: userId,
            ...(internalTraffic ? { traffic_type: 'internal' } : {}),
            debug_mode: internalTraffic,
            page_location: 'n-a',
            custom_map: {
                dimension1: 'issue_count',
            },
        }),
        [],
    );

    useEffect(() => {
        const performInit = async () => {
            // xeslint-disable-next-line no-console
            // console.debug('Initializing GA for given user ID...');
            await init({ baseConfig, userId, tenantUrl });
            setInitialized(true);
        };
        performInit();
    }, []);

    return (
        <AnalyticsConfigContainer config={baseConfig} initialized={initialized}>
            {children}
        </AnalyticsConfigContainer>
    );
};

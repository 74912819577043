/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import { ImgWithFallback } from '@gelatofm/img-utils';
import { jxlLogoIconImg, jxlLogoWordImg } from '@jxl/assets';
import { AnalyticsProvider, ViewTracker } from '@jxl/observability';

import Button from '@atlaskit/button/standard-button';

const rootCss = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const iconImgCss = css`
    width: 96px;
    height: 96px;
    margin-bottom: 20px;
`;

const wordImgCss = css`
    width: 96px;
    margin: 0 0 32px 10px;
`;

export const IndexRoute = () => {
    return (
        <AnalyticsProvider userId="n-a">
            <ViewTracker pageId="root" />
            <div css={rootCss}>
                <ImgWithFallback src={jxlLogoIconImg} alt="" css={iconImgCss} />
                <ImgWithFallback src={jxlLogoWordImg} alt="" css={wordImgCss} />
                <Button appearance="subtle-link" href="https://jxl.app">
                    Learn more
                </Button>
            </div>
        </AnalyticsProvider>
    );
};

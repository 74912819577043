// eslint-disable-next-line max-classes-per-file
export class InvalidStateError extends Error {
    constructor(...variables: string[]) {
        super(`Invalid state; ${variables.join(', ')} not available`);
    }
}

export class PromiseTimeoutError extends Error {
    constructor(message: string) {
        super(message);
        this.responseText = message;
        this.error = 'Timeout error';
    }

    responseText: string;

    error: string;
}

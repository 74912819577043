import { InvalidStateError } from '@gelatofm/errors';
import { createContainer, createHook, createStore } from 'react-sweet-state';

interface Props {
    config: Record<string, unknown>;
    initialized: boolean;
}

interface State {
    config?: Record<string, unknown>;
    initialized: boolean;
}

const initialState: State = {
    initialized: false,
};

const actions = {};

const Store = createStore<State, typeof actions>({
    initialState,
    actions,
    name: 'analytics-config',
});

export const AnalyticsConfigContainer = createContainer<State, typeof actions, Props>(Store, {
    onInit: () => ({ setState }, { config, initialized }) => {
        setState({ config, initialized });
    },
    onUpdate: () => ({ setState }, { config, initialized }) => {
        setState({ config, initialized });
    },
});

const useAnalyticsConfigInternal = createHook(Store, {
    selector: (state) => state.config,
});

export const useAnalyticsConfig = () => {
    const [analyticsConfig] = useAnalyticsConfigInternal();
    if (!analyticsConfig) {
        throw new InvalidStateError('analyticsConfig');
    }
    return analyticsConfig;
};

const useIsAnalyticsInitializedInternal = createHook(Store, {
    selector: (state) => state.initialized,
});

export const useIsAnalyticsInitialized = () => {
    const [initialized] = useIsAnalyticsInitializedInternal();
    return initialized;
};

/* eslint-disable camelcase */

import { useEffect } from 'react';

import { gaTrackingId } from '@jxl/environment';

import { useAnalyticsConfig, useIsAnalyticsInitialized } from '../../controllers/analytics-config';

const performPageView = (pageId: string, baseConfig: Record<string, unknown>) => {
    // xeslint-disable-next-line no-console
    // console.debug(`Send page-view event for page ID ${pageId}...`);

    window.gtag('config', gaTrackingId, {
        ...baseConfig,
        page_path: `/${pageId}`,
        page_title: pageId,
    });
};

export const ViewTracker = ({ pageId }: { pageId: string }) => {
    const initialized = useIsAnalyticsInitialized();
    const baseConfig = useAnalyticsConfig();

    useEffect(() => {
        if (initialized) {
            performPageView(pageId, baseConfig);
        }
    }, [initialized, pageId]);

    return null;
};
